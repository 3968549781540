'use client'

import { clsx } from 'clsx'
import { ReactElement } from 'react'
import Icon from '@/components/tokens/Icon'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
} from '@/lib/types'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  color?: ButtonColor
  textColor?: ButtonColor
  font?: ButtonFont
  type?: 'button' | 'submit' | 'reset'
  label?: string | ReactElement
  icon?: string
  iconHeight?: number
  iconWidth?: number
  disabled?: boolean
  adornment?: string
  adornmentPosition?: 'left' | 'right'
  adornmentSize?: number | undefined
  responsiveness?: ButtonResponsiveness
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isSubmitting?: boolean
  styleOverride?: string
  buttonLabelOverride?: string
  submittingLabel?: string
}

const Button = (props: ButtonProps) => {
  const {
    variant,
    color,
    textColor,
    font,
    type,
    label,
    icon,
    adornment,
    adornmentPosition,
    adornmentSize,
    onClick,
    onMouseEnter,
    disabled,
    responsiveness,
    isSubmitting = false,
    styleOverride,
    buttonLabelOverride,
    submittingLabel = 'Processing',
  } = props

  return (
    <button
      className={clsx(
        'relative box-border hover:opacity-80',
        'flex  items-center justify-center disabled:pointer-events-none',
        color === ButtonColor.Solid &&
          'border-1 border-solid border-blue-82 bg-blue-82 text-white disabled:border-grey-8 disabled:bg-grey-8 disabled:text-grey-45',
        color === ButtonColor.Blue &&
          'border-1 border-solid border-blue-82 bg-white text-blue-82 disabled:border-blue-25 disabled:bg-white disabled:text-blue-25',
        color === ButtonColor.Black &&
          'border-1 border-solid border-black bg-white text-black disabled:border-grey-10 disabled:bg-white disabled:text-grey-10 md:hover:opacity-70',
        color === ButtonColor.Grey &&
          'border-1 border-solid border-grey-45 bg-white text-black  disabled:border-grey-10 disabled:bg-white disabled:text-grey-10',
        color === ButtonColor.White &&
          'border-1 border-solid border-white bg-white text-white disabled:bg-white disabled:text-grey-10',
        color === ButtonColor.Warning &&
          'border-1 border-solid border-grey-45 bg-white text-red-50 disabled:border-grey-10 disabled:bg-white disabled:text-red-25',
        textColor === ButtonColor.Black && 'text-black md:hover:opacity-70',
        textColor === ButtonColor.White && 'text-white',
        textColor === ButtonColor.Blue && 'text-blue-82',
        textColor === ButtonColor.Grey && 'text-grey-45',
        font === ButtonFont.Body1 && '!body-1',
        font === ButtonFont.UI1 && 'ui-1',
        font === ButtonFont.Caption1 && 'caption-1',
        variant === ButtonVariant.Unstyled &&
          '!justify-start border-none bg-none px-0 py-0 md:hover:opacity-70',
        variant === ButtonVariant.Large &&
          'h-[40px] items-center justify-center px-12 py-10',
        variant === ButtonVariant.Small &&
          'h-[28px] items-center justify-center px-12 py-4',
        variant === ButtonVariant.Square &&
          'h-[40px] w-[40px] items-center justify-center border-black',
        variant === ButtonVariant.Icon &&
          'h-[20px] w-[20px] items-center justify-center border-white',
        variant === ButtonVariant.SquareBorderWhite &&
          'h-[40px] w-[40px] border-white',
        variant === ButtonVariant.Square &&
          color === ButtonColor.Solid &&
          'border-blue-82',
        responsiveness === ButtonResponsiveness.Mobile && 'w-full md:w-auto',
        responsiveness === ButtonResponsiveness.Full && 'w-full',
        responsiveness === ButtonResponsiveness.MinW && 'min-w-min',
        isSubmitting && 'z-50 cursor-not-allowed',
        styleOverride,
      )}
      onMouseEnter={(e) => {
        e.stopPropagation()

        if (onMouseEnter) {
          onMouseEnter(e)
        }
      }}
      onClick={(e) => {
        // e.stopPropagation()

        if (onClick) {
          onClick(e)
        }
      }}
      disabled={disabled}
      type={type}
    >
      {adornment && adornmentPosition === 'left' && (
        <Icon
          name={adornment}
          className={clsx(
            color === ButtonColor.Solid && !disabled && 'white',
            color != ButtonColor.Solid && disabled && 'grey-10',
            color === ButtonColor.Solid && disabled && 'grey-45',
            color === ButtonColor.Grey && 'grey-45',
            !color && 'black md:hover:opacity-70',
          )}
          height={adornmentSize || 20}
          width={adornmentSize || 20}
        />
      )}
      {!isSubmitting && (
        <p
          className={clsx(
            `whitespace-nowrap ${
              buttonLabelOverride ? buttonLabelOverride : ''
            }`,
          )}
        >
          {label}
        </p>
      )}
      {isSubmitting && (
        <>
          <p
            className={`relative w-min  whitespace-nowrap ${buttonLabelOverride}`}
          >
            {submittingLabel}
            <span className="pending r-[12px] absolute"></span>
          </p>
        </>
      )}

      {icon && (
        <Icon
          name={icon}
          className={clsx(
            color === ButtonColor.Solid && !disabled && 'white',
            color != ButtonColor.Solid && disabled && 'grey-10',
            color === ButtonColor.Solid && disabled && 'grey-45',
            'black',
          )}
          height={20}
          width={20}
        />
      )}

      {adornment && adornmentPosition === 'right' && (
        <Icon
          name={adornment}
          className={clsx(
            color === ButtonColor.Solid && !disabled && 'white',
            color != ButtonColor.Solid && disabled && 'grey-10',
            color === ButtonColor.Solid && disabled && 'grey-45',
            'black ml-[6px] scale-[1.55]',
          )}
        />
      )}
    </button>
  )
}

export default Button
